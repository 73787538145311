<template>
  <div>
    <nav
      class="navbar navbar-expand navbar-light fixed-top"
      style="max-height: 63px !important"
    >
      <div class="container">
        <a class="navbar-brand" href="#">
          <img
            @click="clickLogo()"
            class="d-block"
            src="/img/LogoPortoBank1.svg"
            height="23vh"
          />
        </a>
        <button
          class="navbar-toggler navbar-toggler-right border-0 collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbar4"
          style=""
          aria-expanded="false"
        >
          <span class="navbar-toggler-icon small"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbar4" style="">
          <p class="small text-info title-space mb-2 mt-2" style="">
            Negocie Porto Bank
          </p>
          <ul class="navbar-nav ml-auto text-center"></ul>
          <div class="space1 d-none d-sm-block"></div>
          <div class="text-center" v-if="!$route.query.cod">
            <a class="btn btn-zul ml-auto" href="#" @click="login">Negociar</a>
          </div>
        </div>
      </div>
    </nav>
    <flash-message
      transitionIn="animated swing"
      class="alert-system"
    ></flash-message>
    <Login height="100%"></Login>

    <div class="footer1 text-light py-4">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5"></div>
        </div>
      </div>
    </div>
    <a
      @click="saveLogcontatoWhatsApp"
      class="whatsapp-link"
      href="https://porto.vc/whatsapp-oficial"
      target="_blank"
    >
      <i
        v-bind:class="[
          largura < 500 ? 'fa fa-whatsapp fa-2x' : 'fa fa-whatsapp fa-3x',
        ]"
      ></i>
    </a>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Login from "@/modules/auth/Login";
export default {
  name: "Home",
  components: {
    Login,
  },
  data: () => ({
    hrefwhatsapp: "https://api.whatsapp.com/send?l=pt&phone=551132303765",
    links: [
      { icon: "", text: "Quem Somos", route: "somos" },
      { icon: "", text: "Experimente", route: "experimente" },
      {
        icon: "",
        text: "Termos de uso",
        route: "termos",
        params: "Termos de uso",
      },
      {
        icon: "",
        text: "Política de privacidade",
        route: "sobre",
        params: "Política de privacidade",
      },
      {
        icon: "",
        text: "Política de cookies",
        route: "sobre",
        params: "Política de cookies",
      },
    ],
    largura: null,
    pageName: "",
    ipUser: null,
  }),
  methods: {
    ...mapActions("meusBoletos", ["ActionSendAccessLogWithoutToken"]),
    ...mapActions("auth", ["ActionSetCurrentForm", "ActionContatoWhatsapp"]),

    async openWhats() {
      if (this.user != null) {
        var whats = await this.ActionGetWhatsText();
        this.hrefwhatsapp = this.hrefwhatsapp + "&text=" + whats[0];
      } else {
        this.ipUser = localStorage.getItem("ipUser");
        await this.ActionSendAccessLogWithoutToken({
          ValueId: 16,
          IP: this.ipUser ? this.ipUser : "",
          OS: navigator.platform,
        });
        this.hrefwhatsapp =
          this.hrefwhatsapp +
          "&text=Olá, não encontrei meus dados podem me ajudar ?";

        var document = window.document.getElementsByName("docnumber")[0].value;

        if (document != "" && document != null)
          this.hrefwhatsapp =
            this.hrefwhatsapp + " Meu documento é : " + document;
      }

      window.open(this.hrefwhatsapp, "_blank");
    },

    login() {
      this.ActionSetCurrentForm("form-doc-number");
    },

    async saveLogcontatoWhatsApp() {
      this.ipUser = localStorage.getItem("ipUser");
      console.log("this.ipUser homelayout: " + JSON.stringify(this.ipUser));
      this.ActionContatoWhatsapp({
        DocNumber: localStorage.getItem("doc") ?? null,
        tag: "Padrao",
        IP: this.ipUser ? this.ipUser : "",
        OS: navigator.platform,
      });
      this.clickWhatsapp();
    },

    clickLogo() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "Login"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:logo:porto-bank";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      console.log(
        "eventData clickLogo HOMELAYOUT: " + JSON.stringify(eventData)
      );

      window.dataLayer.push({ event: "select_content", ...eventData });

      localStorage.clear();

      window.location.href = "/";

      this.login();
    },

    clickWhatsapp() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "Login"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:fab:whatsapp";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      console.log("eventData: " + JSON.stringify(eventData));

      window.dataLayer.push({ event: "select_content", ...eventData });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  created() {
    this.largura = window.screen.width;
  },
};
</script>
<style>
</style>
