var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-link position-absolute",style:({
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    padding: '0',
    zIndex: '2',
  }),attrs:{"type":"button"},on:{"click":_vm.togglePasswordVisibility}},[_c('i',{class:!_vm.isPasswordVisible ? 'fa fa-1x fa-eye-slash' : 'fa fa-1x fa-eye'})])
}
var staticRenderFns = []

export { render, staticRenderFns }